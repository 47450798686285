#banner {
  position: relative;
}
#banner div {
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
}
#banner div p {
  border-bottom: 5px solid white;
  font-size: 7em;
  color: white;
}

@media only screen and (max-width: 1280px) {
  #banner div {
    height: 70vw;
  }
  #banner div p {
    font-size: 4.7em;
  }
}
@media only screen and (max-width: 768px) {
  #banner div p {
    border-bottom: 2px solid white;
    font-size: 2.7em;
  }
}
@media only screen and (max-width: 480px) {
  #banner div p {
    border-bottom: 2px solid white;
    font-size: 2em;
  }
}
