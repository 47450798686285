#footer {
  padding: 50px 0;
  background-color: rgba(230, 230, 250, 0.7);
}

@media only screen and (max-width: 1280px) {
  #footer div {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
