#content {
  padding: 20px 0;
}
#content > * {
  width: 1250px;
}

.l-10 {
  padding-left: 10px;
}
.l-20 {
  padding-left: 20px;
}
.l-30 {
  padding-left: 30px;
}
.l-25 {
  padding-left: 25px;
}
.l-35 {
  padding-left: 35px;
}
.l-45 {
  padding-left: 45px;
}

@media only screen and (max-width: 1250px) {
  #content {
    padding: 20px 10px;
  }
  #content > * {
    width: 100%;
  }
}
