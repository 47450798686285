@font-face {
  font-family: "Nunito";
  src: url("../font/Nunito-Regular.ttf");
}

body {
  font-family: "Nunito";
  background-color: white;
}

.center-mid {
  display: flex;
  justify-content: center;
  align-items: center;
}
.center-row {
  display: flex;
  align-items: center;
}
.center-col {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.text-center {
  text-align: center;
}
.text-orangered {
  color: orangered;
}
.text-white {
  color: white;
}

.fl-left {
  float: left;
}
.fl-right {
  float: right;
}
