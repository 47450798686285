.title {
  padding-left: 10px;
}

.control {
  height: 50px;
  border-radius: 10px;
}
.control:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.control-image {
  height: 400px;
  border-radius: 10px;
}
.control-image:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.customer {
  padding: 10px;
  border-bottom: 1px solid black;
}
.customer img {
  padding: 15px;
  border-radius: 50px;
}
