.navbar-default {
  position: absolute;
  top: 0;
  z-index: 1;
}
.navbar-default > img {
  display: none;
}
.navbar-default a img {
  height: 100px;
  margin: 20px 0 0 100px;
}
.navbar-default ul {
  margin: 70px 50px 0 0;
  list-style: none;
  float: right;
}
.navbar-default ul li {
  padding: 10px 30px;
  margin-right: 20px;
  float: left;
}
#navbar ul li:hover {
  border-radius: 40px;
  background-color: rgba(0, 0, 0, 0.6);
}
#navbar ul a li {
  font-weight: bold;
  text-shadow: 1px 1px 2px;
  color: white;
}
.navbar-default ul a li {
  font-size: 1.5em;
}

.navbar-min {
  height: 100px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);
}
.navbar-min > img {
  width: 40px;
  margin: 15px;
  border-radius: 5px;
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  background-color: rgb(138, 138, 138);
}
.navbar-min > img:hover {
  background-color: rgb(211, 211, 211);
}
.navbar-min a img {
  height: 60px;
}
.navbar-min ul li {
  padding: 10px 30px;
  list-style: none;
  float: left;
}
.navbar-min ul a li {
  font-size: 1.5em;
}

@media only screen and (max-width: 1280px) {
  .navbar-default {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .navbar-default a img {
    height: 80px;
    margin: 20px 0;
  }
  .navbar-default ul {
    margin: 0;
  }
}

@media only screen and (max-width: 1024px) {
  .navbar-min ul li {
    padding: 10px 13px;
  }
  .navbar-min ul a li {
    font-size: 1.1em;
  }
}

@media only screen and (max-width: 768px) {
  .navbar-default a img {
    height: 60px;
    margin: 10px;
  }
  .navbar-default ul li {
    padding: 10px 15px;
    margin-right: 10px;
    float: left;
  }
  .navbar-default ul a li {
    font-size: 1em;
  }

  .navbar-min {
    padding: 10px;
    height: 70px;
    flex-direction: column;
  }
  .navbar-min > img {
    display: block;
  }
  .navbar-min a img {
    height: 50px;
  }
  .navbar-min ul li {
    width: 100vw;
    padding: 10px 30px;
    background-color: rgba(0, 0, 0, 0.6);
  }
  .navbar-min ul a li {
    text-align: center;
    font-size: 1.1em;
  }
}
@media only screen and (max-width: 480px) {
  .navbar-default a img {
    height: 40px;
    margin: 5px;
  }
  .navbar-default ul a li {
    font-size: 0.8em;
  }
}
