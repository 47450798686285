#list-tai-lieu {
  padding: 10px;
  border-radius: 20px;
  list-style: none;
  background-color: rgba(0, 0, 0, 0.4);
}
#list-tai-lieu li {
  padding-left: 10px;
  margin-top: 6px;
  border-bottom: 1px solid;
  border-right: 1px solid;
  border-radius: 10px;
  color: white;
}
#list-tai-lieu li:hover {
  color: black;
  background-color: white;
}

#document {
  opacity: 0.1;
  height: 600px;
}
