.supplier-name {
  padding: 40px 10px 4px;
  margin-bottom: 10px;
  border-bottom: 2px solid red;
}
.produce-list {
  display: grid;
  border-radius: 20px;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: auto auto auto;
  background-color: rgb(243, 243, 243);
}
.produce {
  padding: 10px;
}
.produce img {
  width: 100%;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .produce-list {
    grid-template-columns: 50% 50%;
  }
}
